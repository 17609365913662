import { useStoreOpen } from 'lib/hooks';
import { getStoreOpeningAndClosing, getTodaysHours, sluggedName } from 'lib/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react'
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Flex } from 'components/layout/shared';
import moment from 'moment';
import Rating from 'react-rating'
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa'
import useHeaderImage from 'custom-hooks/useHeaderImage';

export default function StoreItem({ store, useHeader }) {
    const router = useRouter()
    const { acceptsCash, acceptsPayouts, address, categories, doesDelivery, logo, headerImage, headerThumb, name, onHiredDriverNetwork, openingDays, rating=0, storeSlug } = store;
    const todayHours = getTodaysHours(openingDays);
    const { isOpen } = useStoreOpen(openingDays, todayHours, getStoreOpeningAndClosing(todayHours.openingTimeObj, todayHours.closingTimeObj));
    
    const placeholderHeaderImage = useHeaderImage(categories ? categories[0] : '')

    const doesDeliveryOnly = useMemo(() => {
        return (store.doesDelivery && !store.doesCurbside && !store.doesWalkin)
    }, [store])

    const isNewStore = useMemo(() => {
        const weekAgo = moment().subtract(7, 'days')
        if (!store.createdAt) {
            return false
        }else {
            // const storeCreated = moment(store.createdAt.toDate())
            const storeCreated = store.createdAt.seconds ? moment(store.createdAt.seconds  * 1000) : moment(store.createdAt._seconds  * 1000)
            return storeCreated.isSameOrAfter(weekAgo)
        }
    }, [store])

    return (
        <Container onClick={() => {
            router.push(`/store/${storeSlug ?? sluggedName(name)}`)
        }}>
            {useHeader ?
                <HeaderContainer>
                    <HeaderLogo src={logo} />
                    <Header src={headerThumb ?? headerImage ?? placeholderHeaderImage } />
                </HeaderContainer>
                :
                <Logo src={logo} />
            }
            <div>
                <Name>{name}</Name>
                {(!address || !doesDeliveryOnly) && <Address>{address.address.replace(', Trinidad and Tobago', '')}</Address>}
                <Description>{acceptsPayouts && 'Pay online'}{(doesDelivery && onHiredDriverNetwork) ? ' • Delivery anywhere in Trinidad' : doesDelivery && " • Delivery available"}{isOpen && ' • Open today'}</Description>
                <Rating
                    initialRating={rating}
                    emptySymbol={<FaRegStar  color='#ccc' />}
                    fullSymbol={<FaStar color={'rgb(215, 161, 0)'}/>}
                    readonly
                />
            </div>
            <Tags direction="row" align="center">
                {(store.onSaleCount && store.onSaleCount > 0) ?
                    <Tag color={colors.sale}>
                        <TagLabel>SALE!</TagLabel>
                    </Tag>
                    : []
                }
                {isNewStore &&
                    <Tag color={colors.primary}>
                        <TagLabel color={colors.base}>NEW</TagLabel>
                    </Tag>
                }
            </Tags>
        </Container>
    )
}

const Container = styled.div`
    cursor: pointer;
    border: 0.6px solid #E2E2E2;
    border-radius: 10px;
    padding: 10px;
    position: relative;
`;
const Name = styled.p`
    font-size: 20px;
    font-family: 'PhantomSans';
    margin-bottom: 2px;  
    font-weight: 400;
    line-height: 1.2;
    word-break: break-word;
`;
const Address = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
    opacity: 0.63;
`
const HeaderLogo = styled.img`
    width: 45px;
    height: 45px;
    background: ${colors.base};
    object-fit: cover;
    position: absolute;
    left: 10px;
    bottom: 15px;
    border: 2px solid white;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);


    @media (max-width: 550px) {
        height: 55px;
        width: 55px;
        bottom: 10px;
    }
`;
const Logo = styled.img`
    /* width: 150px; */
    height: 140px;
    margin-bottom: 14px;
    object-fit: cover;

    @media (max-width: 550px) {
        height: 120px;
    }
`;
const HeaderContainer = styled.div`
    /* width: 240px; */
    width: 100%;
    background: #eee;
    height: 120px;
    position: relative;
    margin-bottom: 8px;
    min-width: 260px;

    @media (max-width: 800px){
        min-width: unset;
    }
`;
const Header = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    outline: none;

`;
const Description = styled.p`
    margin-bottom: 8px;
    font-size: 14px;
    font-family: 'PhantomSans';
    line-height: 1.2;
    opacity: 0.7;
`;
const Tags = styled(Flex)`
    position: absolute;
    top: -5px;
    left: -5px;
`;
const Tag = styled.div`
    background: ${props => props.color ? props.color : colors.base};
    padding: 5px 9px;
    margin-right: 7px;
`;
const TagLabel = styled.p`
    color: ${props => props.color ? props.color : 'white'};
    font-size: 12px;
    font-weight: 600;
    margin: 0
`;